<template>
  <v-card>
    <v-card-title>
      {{
        this.editSections ? `Editar ${newSection.name}` : "Crear nueva sección"
      }}
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-form class="mt-6" ref="form" v-model="valid" lazy-validation>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-text-field
              style="border-radius: 10px"
              label="Nombre"
              filled
              v-model="newSection.name"
              :rules="requerid"
              required
              type="text"
              rounded
              dense
            ></v-text-field>
            <v-text-field
              style="border-radius: 10px"
              label="Capacidad maxima de entradas"
              filled
              v-model.number="newSection.maxQuantity"
              :rules="requerid"
              required
              :disabled="Capacity > 0"
              @keypress="isNumber"
              rounded
              dense
            >
              <template v-slot:append>
                <div class="text-ofm">
                  <span
                    >Entradas con <br />
                    esta Sección</span
                  >
                  <b>{{ Capacity }}</b>
                </div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="6">
            <v-switch
              dense
              label="Activo"
              v-model="newSection.active"
            ></v-switch>
          </v-col>
          <v-col cols="6" v-if="user.type == 'sudo'">
            <v-switch
              dense
              label="Activo por ocho"
              v-model="newSection.ochoActive"
            ></v-switch>
          </v-col>
          <v-col cols="6">
            <v-switch
              dense
              label="Es gratis"
              v-model="newSection.isFree"
            ></v-switch>
          </v-col>

          <v-col cols="6">
            <v-switch
              dense
              label="Muestra el contador"
              v-model="newSection.showsCounter"
            ></v-switch>
          </v-col>
          <v-col cols="6">
            <v-switch
              dense
              label="Está agotado"
              v-model="newSection.isSoldOut"
            ></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="actions-btn">
      <v-btn
        color="primary"
        text
        :loading="btnloading"
        style="border-radius: 10px"
        @click="$emit('closed')"
        >cancelar</v-btn
      >
      <v-btn
        :loading="btnloading"
        color="primary"
        style="border-radius: 10px"
        @click="editSections ? updateSection() : addUpdateEvent()"
        :disabled="!valid"
      >
        {{ editSections ? "Guardar" : "Aceptar" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import store from "@/store/index.js";

import { LMap, LTileLayer, LIcon, LMarker, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
var randomstring = require("randomstring");
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
export default {
  name: "formNewEvent",

  components: {
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LTooltip,
    VGeosearch,
  },
  props: ["editSections"],
  data() {
    return {
      countries_: [],
      cities_: [],
      tab: null,
      allTickets: [],
      Capacity: 0,
      valid: true,
      Code: null,
      imagedefaul: require("@/assets/templateImage.svg"),
      dialog: false,
      loading: true,
      btnloading: false,
      hoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      File: null,
      menu2: false,
      requerid: [(v) => !!v || "Campo requerido"],
      ruleCapacity: [
        (v) => !!v || "Campo requerido",
        (v) =>
          (v && v >= this.Capacity) || "Capacidad debe ser mayor a la actual",
      ],

      newSection: {
        amountSold: 0,
        code: "",
        country: "",
        name: "",
        isSoldOut: false,
        active: false,
        isFree: false,
        showsCounter: false,
        ochoActive: false,
        deleted: false,
        city: "",
      },
    };
  },
  computed: {
    ...mapState(["business", "user", "selectedEvent"]),
  },
  methods: {
    ...mapActions([
      "_searchTerm",
      "Alert_",
      "addEventSelection",
      "visibleSearch",
    ]),
    generateCode() {
      if (this.newSection.name) {
        return `${this.newSection.name
          .toUpperCase()
          .substr(0, 4)}-${randomstring.generate(8).toUpperCase()}`;
      } else {
        return `SEC-${randomstring.generate(6).toUpperCase()}`;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 31
      ) {
        evt.preventDefault();
      } else {
       // console.debug(evt);
        return true;
      }
    },
    updateSection() {
      this.btnloading = true;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const sections = this.newSection;
        delete sections[`.key`];
        sections.modifiedAt = new Date();
        sections.modifiedBy = this.user[`.key`];
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("sections")
          .doc(this.editSections[`.key`])
          .update(sections)
          .then(async (ref) => {
           // console.debug(sections);
            this.Alert_({
              text: "Evento creado correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("closed");
            }, 2000);
          })
          .catch((error) => {
           // console.debug(error);
            this.Alert_({
              text: "Ocurrió un error al crear el Evento",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnloading = false;
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnloading = false;
      }
    },
    async addUpdateEvent() {
      this.btnloading = true;

      // let token = await this.getSectionToken;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const sections = this.newSection;
        sections.business = this.business[`.key`];
        sections.eventId = this.selectedEvent[`.key`];
        sections.country = this.selectedEvent.country;
        sections.active = this.newSection.active ? true : false;
        sections.code = this.generateCode();
        sections.createdBy = this.user[`.key`];
        sections.createdAt = new Date();
        sections.deleted = false;
        sections.city = this.selectedEvent.city;

        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("sections")
          .add(sections)
          .then(async (ref) => {
           // console.debug(sections);
            this.Alert_({
              text: "Sección creada correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("closed");
            }, 2000);
          })
          .catch((error) => {
           // console.debug(error);
            this.Alert_({
              text: "Ocurrió un error al crear la Sección",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnloading = false;
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnloading = false;
      }
    },
  },
  async mounted() {
    this._searchTerm("Eventos");
    this.visibleSearch(true);
    await this.$binding(
      "allTickets",
      db
        .collection("oViveTickets")
        .where("event", "==", this.selectedEvent[`.key`])
        .where("deleted", "==", false)
        .where("active", "==", true)
    );
    await this.$binding(
      "countries_",
      db
        .collection("countries")
        .where("active", "==", true)
        .where("deleted", "==", false)
    );
    await this.$binding(
      "cities_",
      db
        .collection("cities")
        .where("active", "==", true)
        .where("deleted", "==", false)
    );

    if (this.editSections) {
      this.newSection = Object.assign({}, this.editSections);

      var tickets = this.allTickets.filter(
        (x) => x.section == this.editSections[`.key`]
      );

      this.Capacity = 0;
     // console.debug(tickets);

      for (let i = 0; i < tickets.length; i++) {
        const element = tickets[i];
       // console.debug(element.availableAmount);
        this.Capacity = this.Capacity + element.availableAmount;
      }
    }
  },
};
</script>
<style lang="scss">
.theme--dark.v-tabs-items {
  background-color: #1e1e1e00 !important;
}
.upload {
  display: none;
}
.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
  color: #000 !important;
}
.text-ofm {
  display: flex;
  align-items: center;
  justify-items: center;
  span {
    font-size: 0.8em;
  }
  b {
    font-size: 1.5em;
    margin-left: 10px;
  }
}
</style>
