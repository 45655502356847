<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <headerLayaut>
      <v-btn
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="openSort"
        >Ordenar</v-btn
      >
      <v-btn
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialog = true"
        >Crear Sección</v-btn
      >
    </headerLayaut>
    <!-- contenido de la pagina -->

    <div class="body-template">
      <v-data-table
        :headers="headers"
        :items="allSections"
        sort-by="position"
        fixed-header
        :search="$store.state.search"
        :calculate-widths="true"
        sort-asc
        item-key="id"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }"
      >
        <template v-slot:[`item.code`]="{ item }">
          <v-chip color="gray">
            <span>
              {{ item.code }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-switch
            @change="
              switchControlChanged(
                { active: item.active },
                item['.key'],
                item,
                'active'
              )
            "
            v-model="item.active"
          >
          </v-switch>
        </template>
        <template v-slot:[`item.ochoActive`]="{ item }">
          <v-switch
            dense
            @change="
              switchControlChanged(
                { ochoActive: item.ochoActive },
                item['.key'],
                item,
                'ochoActive'
              )
            "
            v-model="item.ochoActive"
          ></v-switch>
        </template>
        <template v-slot:[`item.isSoldOut`]="{ item }">
          <v-switch
            dense
            v-model="item.isSoldOut"
            @change="
              switchControlChanged(
                { isSoldOut: item.isSoldOut },
                item['.key'],
                item,
                'isSoldOut'
              )
            "
          ></v-switch>
        </template>
        <template v-slot:[`item.isFree`]="{ item }">
          <v-switch
            dense
            v-model="item.isFree"
            @change="
              switchControlChanged(
                { isFree: item.isFree },
                item['.key'],
                item,
                'isFree'
              )
            "
          ></v-switch>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                :elevation="0"
                @click="slelectEdit(item)"
                color="warning"
              >
                <v-icon small dark>fa-solid fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Editar {{ item.name }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                :elevation="0"
                @click="selectedDeleted(item)"
                color="#d42828"
              >
                <v-icon small dark>fa-solid fa-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Eliminar {{ item.name }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialogEdit" v-if="dialogEdit" scrollable width="600px">
      <formNewSection
        :editSections="dataEdit"
        @closed="dialogEdit = !dialogEdit"
      />
    </v-dialog>
    <v-dialog v-model="dialog" v-if="dialog" scrollable width="600px">
      <formNewSection @closed="dialog = !dialog" />
    </v-dialog>

    <!-- eliminar -->
    <v-dialog
      v-model="deleteConfirm"
      v-if="deleteConfirm"
      min-width="350px"
      max-width="400px"
    >
      <v-card class="pa-2">
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          small
          absolute
          @click="deleteConfirm = false"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
        <v-card-title class="pr-4"><h3>¿ Eliminar sección ?</h3> </v-card-title>
        <v-card-subtitle class="mt-2">
          {{ deletedkey.name }}
        </v-card-subtitle>

        <v-card-actions class="flex-end">
          <v-btn color="primary" :loading="btnLoading" @click="deletedSections">
            Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sortSections" max-width="400px" persistent scrollable>
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="sortSections = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>

          Ordenar
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <draggable
            v-model="allSectionsSort"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <v-list-item
              v-for="(element, index) in allSectionsSort"
              :key="index"
              class="grab"
              :class="{ linea: index < allSectionsSort.length - 1 }"
            >
              <v-list-item-title class="list-wrap-img">
                {{ element.name }}
              </v-list-item-title>
              <v-icon color="primary"> fa-solid fa-sort </v-icon>
            </v-list-item>
          </draggable>
        </v-card-text>
        <v-divider> </v-divider>
        <v-card-actions class="flex-end">
          <v-btn
            color="primary"
            style="border-radius: 10px"
            width="200px"
            @click="saveSort"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLayaut from "@/components/header-layaut.vue";
import formNewSection from "@/components/formNewSection.vue";
import draggable from "vuedraggable";
import { db, fb } from "@/firebase";
export default {
  name: "sections",

  components: {
    headerLayaut,
    formNewSection,
    draggable,
  },
  data() {
    return {
      dialogEdit: false,
      dataEdit: null,
      sortSections: false,
      btnLoading: null,

      deleteConfirm: false,
      deletedkey: null,
      dialog: false,
      allSections: [],
      allSectionsSort: [],
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Código", value: "code" },
        { text: "Cantidad Vendida", value: "amountSold" },
        { text: "Capacidad Max", value: "maxQuantity" },
        { text: "Es gratis", value: "isFree" },
        { text: "Está agotado", value: "isSoldOut" },
        // { text: "Activo por ocho", value: "ochoActive" },
        { text: "Activo", value: "active" },

        { text: " ", value: "options" },
      ],
    };
  },
  computed: {
    ...mapState(["selectedEvent", "user", "business"]),
  },
  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),
    selectedDeleted(id) {
     // console.debug(id);
      this.deletedkey = id;
      this.deleteConfirm = true;
    },
    openSort() {
      this.allSectionsSort = Object.assign([], this.allSections);
      this.sortSections = true;
    },
    slelectEdit(item) {
      this.dataEdit = item;
     // console.debug(this.dataEdit)
      this.dialogEdit = true;
    },
    async saveSort() {
      for (let i = 0; i < this.allSectionsSort.length; i++) {
        const element = this.allSectionsSort[i];

        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("sections")
          .doc(element[`.key`])
          .update({
            position: i,
          });
      }
      this.allSectionsSort = null;
      this.sortSections = false;
      this.Alert_({
        text: "Orden de las secciones Actualizadas",
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: "#00cdbc",
      });
    },
    deletedSections() {
      if (this.deletedkey) {
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("sections")
          .doc(this.deletedkey[`.key`])
          .update({
            deletedAt: new Date(),
            deletedBy: this.user[`.key`],
            deleted: true,
            active: false,
          })
          .then(() => {
            this.Alert_({
              text: "Sección eliminado correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "info",
            });
            this.deleteConfirm = false;
            this.deletedkey = null;
          });
      } else {
        this.deletedkey = null;
        this.deleteConfirm = false;
      }
    },
    switchControlChanged(data, eventId, business, field) {
      let prevData = Object.assign({}, data);
      prevData[field] = !data[field];

      if (data && eventId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.user[`.key`];
       // console.debug(data);
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("sections")
          .doc(eventId)
          .update(data)
          .then(() => {
            this.Alert_({
              text: "Información de la sección actualizada correctamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "#00cdbc",
            });
          })
          .catch((err) => {
           console.log(err);
            this.Alert_({
              text: "Ocurrió un error inesperado, inténtelo nuevamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });

            business[field] = !business[field];
          });
      }
    },
  },
  async mounted() {
    this._searchTerm("Secciones");
    this.visibleSearch(true);

    await this.$binding(
      "allSections",
      db
        .collection("events")
        .doc(this.selectedEvent[`.key`])
        .collection("sections")
        .where("business", "==", this.business[`.key`])
        .where("deleted", "==", false)
    );
  },
};
</script>
<style scoped>
.linea {
  border-bottom: 1px solid rgba(128, 128, 128, 0.088);
}
</style>